<template>
    <a-drawer
        class="search-popup-box"
        title="高级搜索"
        placement="right"
        :closable="false"
        :width="720"
        :visible="visible"
        @close="onClose"
    >
        <div class="search-box">
            <div class="conpany-block">
                <div class="title strong">
                    主办单位
                </div>
                <div class="flex x-left y-center filter-box">
                    <div class="item" :class="[index == committeeIndex ? 'active' : '']" v-for="(item,index) in committees" :key="index" @click="handleChooseIndex('committee',index)">中华医学会</div>
                </div>
            </div>

            <div class="year-block" v-if="awardYears.length">
                <div class="title strong">评奖年份</div>
                <div class="flex x-left y-center filter-box x-left">
                    <div class="item" :class="[index == yearIndex ? 'active' : '']" v-for="(item,index) in awardYears" :key="index" @click="handleChooseIndex('year',index)">{{ item.id }}年</div>
                </div>
            </div>

            <div class="rank-block" v-if="awardLevels.length">
                <div class="title strong">奖项级别</div>
                <div class="flex y-center filter-box x-left">
                    <div class="item" :class="[index == levelIndex ? 'active' : '']" v-for="(item,index) in awardLevels" :key="index" @click="handleChooseIndex('level',index)">{{ item._projAwardName }}</div>
                </div>
            </div>


            <div
                :style="{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e9e9e9',
                  padding: '10px 16px',
                  background: '#fff',
                  textAlign: 'right',
                  zIndex: 1,
                }"
            >
                <a-button type="default" @click="onClose" :style="{ marginRight: '8px' }">取消</a-button>
                <a-button type="primary" @click="handleSure">确定</a-button>
            </div>

        </div>
    </a-drawer>
</template>

<script>
    import yearList from "@/components/year-list.vue";

    export default {
        computed: {
            yearList() {
                return yearList
            }
        },
        props : {
          visible : {
              type : Boolean,
              default : false
          }
        },
        data(){
            return {
                committees : [], // 主办单位
                awardYears : [],// 评奖年份
                awardLevels : [],// 奖项级别
                committeeIndex : 0, // 主办单位下标
                yearIndex : 0, // 评奖年份下标
                levelIndex : 0, // 奖项级别下标
            }
        },
        watch : {
            visible(val){
                if(val){
                    if(this.committees.length == 0){
                        this.getParams();
                    }
                }
            }
        },
        methods : {
            getParams() {
                this.request.post('GetAwardsConditions').then(res => {
                    /**
                     * committees：主办单位
                     * ***/
                    let {committees} = res.data;
                    let awardYears = committees[0].years;
                    let awardLevels = awardYears[0].awards;
                    this.committees = committees;
                    this.awardYears = awardYears;
                    this.awardLevels = awardLevels;
                    console.log(awardYears)
                })
            },
            onClose(){
                this.$emit('close')
            },
            handleSure(){
                let year = this.awardYears[this.yearIndex].id;
                let award_id = this.awardLevels[this.levelIndex]._awardId;
                this.$router.push({
                    path : '/awards/search-result',
                    query : {
                        year,
                        award_id
                    }
                })
            },
            // 选中对应下标
            handleChooseIndex(type,index){
                /**
                 * type : committee 主办单位 year 评奖年份  level 奖项级别
                 * index : 下标
                 * */
                if(type == 'committee'){
                    this.committeeIndex = index;
                    this.awardYears = this.committees[index].years;
                    this.awardLevels = this.awardYears[0].awards;
                    this.yearIndex = 0;
                    this.levelIndex = 0;
                }else if(type == 'year'){
                    this.yearIndex = index;
                    this.awardLevels = this.awardYears[index].awards;
                    this.levelIndex = 0;
                }else if(type == 'level'){
                    this.levelIndex = index;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .search-box{
        .conpany-block,.year-block,.rank-block{
            margin-bottom: 20px;
            .title{
                font-size: 16px;
                margin-bottom: 10px;
            }
            .filter-box{
                flex-wrap: wrap;
                margin-top: 20px;
                .item{
                    width: 200px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:hover,&.active{
                        background: #1E51C9;
                        color: #FFFFFF;
                        border: 1px solid #1E51C9;
                    }
                }
            }
        }
        .year-block{
            .filter-box{
                .item{
                    width: 100px;
                }
            }
        }
    }
    @media screen and (max-width:768px){
        .search-popup-box{
            /deep/.ant-drawer-content-wrapper{
                width:100%!important;
            }
        }
        .search-box{
            .conpany-block,.year-block,.rank-block{
                margin-bottom: 10px;
                .title{
                    font-size: 14px;
                    margin-bottom: 5px;
                }
                .filter-box{
                    .item{
                        width: 48%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 12px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        box-sizing: border-box;
                        &:nth-child(2n){
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
</style>