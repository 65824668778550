var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{staticClass:"search-popup-box",attrs:{"title":"高级搜索","placement":"right","closable":false,"width":720,"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('div',{staticClass:"search-box"},[_c('div',{staticClass:"conpany-block"},[_c('div',{staticClass:"title strong"},[_vm._v(" 主办单位 ")]),_c('div',{staticClass:"flex x-left y-center filter-box"},_vm._l((_vm.committees),function(item,index){return _c('div',{key:index,staticClass:"item",class:[index == _vm.committeeIndex ? 'active' : ''],on:{"click":function($event){return _vm.handleChooseIndex('committee',index)}}},[_vm._v("中华医学会")])}),0)]),(_vm.awardYears.length)?_c('div',{staticClass:"year-block"},[_c('div',{staticClass:"title strong"},[_vm._v("评奖年份")]),_c('div',{staticClass:"flex x-left y-center filter-box x-left"},_vm._l((_vm.awardYears),function(item,index){return _c('div',{key:index,staticClass:"item",class:[index == _vm.yearIndex ? 'active' : ''],on:{"click":function($event){return _vm.handleChooseIndex('year',index)}}},[_vm._v(_vm._s(item.id)+"年")])}),0)]):_vm._e(),(_vm.awardLevels.length)?_c('div',{staticClass:"rank-block"},[_c('div',{staticClass:"title strong"},[_vm._v("奖项级别")]),_c('div',{staticClass:"flex y-center filter-box x-left"},_vm._l((_vm.awardLevels),function(item,index){return _c('div',{key:index,staticClass:"item",class:[index == _vm.levelIndex ? 'active' : ''],on:{"click":function($event){return _vm.handleChooseIndex('level',index)}}},[_vm._v(_vm._s(item._projAwardName))])}),0)]):_vm._e(),_c('div',{style:({
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            })},[_c('a-button',{style:({ marginRight: '8px' }),attrs:{"type":"default"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSure}},[_vm._v("确定")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }