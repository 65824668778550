<template>
    <div class="awards-box">
        <div class="background"></div>
        <div class="flex x-between container top-content">
            <router-link :to="{ path: '/' }" class="return-index t-c fs14 ml20 mt30 mb50">返回首页</router-link>
            <div class="login-btn mt40" v-if="!(userInfo || {}).Mobile">
                <a class="color333 fs14" href="javascript:;" @click="handleLoginEvent">登录</a>
                <span class="pl5 pr5">|</span>
                <a class="color333 fs14" href="javascript:;" @click="handleRegisterEvent">注册</a>
            </div>
            <div class="mt40" v-else>
                <a-dropdown placement="bottomCenter">
                    <a style="height:32px;" class="flex y-center ant-dropdown-link" @click="e => e.preventDefault()">
                        <a-avatar icon="user" :src="userInfo.Avatar || require('@/assets/images/person-default.png')" />
                        <span class="ml10 color666 fs16">{{ userInfo.RealName }}</span>
                        <a-icon class="color333 ml10" type="down" />
                    </a>
                    <a-menu slot="overlay" style="max-width:100px;">
                        <a-menu-item class="t-c">
                            <a target="_blank" :href="personal_url + committee_id">个人中心</a>
                        </a-menu-item>
                        <a-menu-item class="t-c">
                            <a target="_blank" :href="mini_url + 'user/index/' + committee_id">管理中心</a>
                        </a-menu-item>
                        <a-menu-item class="t-c">
                            <a @click="handlelogoutEvent" href="javascript:;">安全退出</a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
        </div>
        <div class="container mb20">
            <img style="width:100%;" src="~@/assets/images/award-banner.jpg" alt="">
        </div>
        <!--    内容部分    -->
        <div class="content container">
            <!-- banner -->
            <div class="banner" v-if="false">
                <a-carousel class="carousel" arrows autoplay :autoplay-speed="4000">
                    <!--autoplay-->
                    <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                        <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right-circle" />
                    </div>
                    <img :src="item.img | urlFilter(360)" alt="" v-for="(item, index) in bannerList" @touchstart="handleBanerToPage(item)" @click="handleBanerToPage(item)" :key="index">
                </a-carousel>
            </div>
            <!--    搜索部分    -->
            <div class="search-box flex y-center x-left">
                <a-input-search class="input-box" placeholder="搜索" enter-button="搜索" @search="onSearch" />
                <a-button class="advanced-search" @click="visible = true">高级搜索</a-button>
            </div>
            <!--    列表   -->
            <div class="list-content mt20">
                <div class="item-box" v-for="(item,index) in infoList" :key="index">
                    <template v-if="item.type == 1">
                        <div class="line flex x-left y-center">
                            <div class="key">奖项级别 :</div>
                            <div :class="['value', 'flex', 'y-center', 'x-left', 'strong', `theme${item.awards_id % 7}`]">{{ item.awards_name }} <span class="ait-icon fs20 med med-medal-fill"></span></div>
                        </div>
                        <div class="line flex x-left y-center" v-if="item._zhuban_name">
                            <div class="key">主办方 :</div>
                            <div class="value">{{ item._zhuban_name }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">项目编号 :</div>
                            <div class="value">{{ item.project_num }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">项目名称 :</div>
                            <div class="value strong">{{ item.project_name_cn }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">主要完成人 :</div>
                            <div class="value">{{ item.main_completed }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">主要完成人单位 :</div>
                            <div class="value">{{ item.main_company }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">推荐单位 :</div>
                            <div class="value">{{ item.related_company }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="line flex x-left y-center">
                            <div class="key">奖项级别 :</div>
                            <div class="value strong theme1">{{ item.awards_name }}</div>
                        </div>
                        <div class="line flex x-left y-center" v-if="item._zhuban_name">
                            <div class="key">主办方 :</div>
                            <div class="value">{{ item._zhuban_name }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">项目编号 :</div>
                            <div class="value">{{ item.project_num }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">拟授奖人 :</div>
                            <div class="value">{{ item.one_completd }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">工作单位 :</div>
                            <div class="value">{{ item.one_company }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">推荐常务理事 :</div>
                            <div class="value strong">{{ item.related_company }}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>


        <!--    高级搜索    -->
        <search-popup :visible="visible" @close="handleClosePopup"></search-popup>
    </div>
</template>

<script>
import Header from "@/components/layout/layout-header.vue";
import {handleBanerToPage} from "@/utils/jumpPageMethods";
import {mapState} from "vuex";
import searchPopup from "@/views/awards/components/search-popup.vue";
import { mini_url, committee_id,personal_url } from '@/config';
import storage from "store";

export default {
    name: "index",
    components : {
        Header , searchPopup
    },
    data(){
        return {
            personal_url,
            mini_url,
            committee_id,
            bannerList: [],  //banner列表
            infoList : [], //科技奖列表
            visible : false,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created(){
        this.getBanner();
        this.getPageList();
    },
    methods : {
        handleBanerToPage,
        getBanner(){
            this.request.post('GetBanner').then(res => {
                this.bannerList = res.data
            })
        },
        // 退出登录
        handlelogoutEvent() {
            storage.set('auth', '');
            storage.set('showSelected', false)
            storage.remove('subjectIds')
            storage.remove('operate_id')
            //this.$store.commit('updateState',{userInfo:null});
            window.location.href = mini_url+'site/logout/'+committee_id+'?return_url='+window.location.origin
        },
        getPageList(value){
            let data = {};
            value && (data.project_keywords = value);
            this.request.post('GetProjectAwards',data).then(res => {
                this.infoList = res.data.list;
            })
        },
        // 登录
        handleLoginEvent() {
            window.location.href = this.mini_url + "unified/login?return_url=" + encodeURIComponent(window.location.href);
            console.log(window.location.href)
        },
        // 注册
        handleRegisterEvent() {
            window.location.href = this.mini_url + "unified/register?return_url=" + encodeURIComponent(window.location.href);
        },
        // 搜索
        onSearch(value){
            this.getPageList(value)
        },
        handleClosePopup(){
            this.visible = false
        },
    }
}
</script>

<style scoped lang="less">
    .awards-box{
        .background {
            width: 100%;
            height: 408px;
            background: #FFFFFF;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        .login-btn {
            height: 20px;
        }
        .return-index {
            width: 92px;
            height: 40px;
            border: 1px solid #1E51C9;
            border-radius: 19px;
            line-height: 38px;
            color: #1E51C9;
            cursor: pointer;
            display: block;
        }

        .search-box{
            padding: 0 40px;
            box-sizing: border-box;
            .advanced-search{
                height: 44px;
                line-height: 44px;
            }
            .input-box{
                flex: 1;
                /deep/ input , /deep/ .ant-input-group-addon button{
                    height: 44px;
                    line-height: 44px;
                }
            }
            .advanced-search{
                margin-left: 20px;
            }
        }

        .list-content{
            padding: 0 40px;
            .item-box{
                border: 1px solid #E5E5E5;
                border-radius: 4px;
                padding: 10px 20px;
                box-sizing: border-box;
                text-align: left;
                margin-bottom: 20px;
                .line{
                    margin-bottom: 4px;
                    .key{
                        color: #666666;
                    }
                    .value{
                        margin-left: 10px;
                        flex: 1;
                        color: #333333;
                        &.theme1{
                            color: #e1554f;
                        }
                        &.theme2{
                            color: #68c87a;
                        }
                        &.theme3{
                            color: #52a6ea;
                        }
                        &.theme4{
                            color: #3436C7;
                        }
                        &.theme5{
                            color: #975fe4;
                        }
                        &.theme6{
                            color: #223273;
                        }
                        &.theme7{
                            color: #FACC14;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:768px){
        .awards-box{
            width: 100%;
            margin-top: -80px;
            .container{
                padding: 10px;
                margin-bottom: -20px;
                .mt40{
                    margin-top: 35px;
                }
                .mb50{
                    margin-bottom: 20px;
                }
                .return-index{
                    margin-left: 0;
                }
            }
            .search-box,.list-content{
                padding: 0 10px;
            }
            .search-box{
                .advanced-search{
                    height: 36px;
                    line-height: 36px;
                }
                .input-box{
                    flex: 1;
                    /deep/ input , /deep/ .ant-input-group-addon button{
                        height: 36px;
                        line-height: 36px;
                    }
                }
            }
            .item-box{
                margin-bottom: 10px !important;
                padding: 10px !important;
                .line{
                    margin-bottom: 0 !important;
                }
                .y-center{
                    align-items: start;
                }
            }
        }
    }
</style>